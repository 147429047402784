// ============================================================
// ATTENTION & COMMON RULE!!
// 関数を実装のみ（処理の実行は下部で実行する）
// 関数名には振る舞いを表す英語プラスFuncを付ける
// ============================================================

// レスポンシブ判定 & デバイス判定関数（PC,SP共通処理）
// -------------------------------------------------------------

function mq(device) {
  if (window.matchMedia("(max-width:768px)").matches && device === "sp") {
    return true;
  } else if (
    window.matchMedia("(min-width:769px)").matches &&
    device === "pc"
  ) {
    return true;
  }
  return false;
}
// FUNCTIONS
function topPageFunc() {
  if ($("#firstWrap").length) {

  var headerWrap = document.getElementById("headerWrap");
  var firstWrap = document.getElementById("firstWrap");

  setTimeout(function() {
    firstWrap.classList.add("show");
    headerWrap.classList.add("show");
  }, 1000);

  $("#infiniteslide").infiniteslide({
    speed: 10, //speed this is px/min
    direction: "left", //choose  up/down/left/right
    pauseonhover: false, //if true,stop onmouseover
    responsive: true, //width/height recalculation on window resize. child element's width/height define %/vw/vh,this set true.
    clone: 1 //if child elements are too few (elements can't "infinite"), set 2 or over.
  });

  $('.popup-modal').magnificPopup({
    type: 'inline',
    preloader: false
  });
  //閉じるリンクの設定
  $('.popup-modal-dismiss').on('click', function (e) { 
    e.preventDefault();
    $.magnificPopup.close();
  });

}
}

function subPageFunc() {
  

  if ($("#blogWrap").length && mq("sp")) {
    var infScroll = new InfiniteScroll(".infinity-list", {
      // 記事を挿入していく要素を指定
      append: ".post", // 各記事の要素
      path: ".next_posts_link a", // 次のページへのリンク要素を指定
      hideNav: ".next_posts_link", // 指定要素を非表示にする（ここは無くてもOK）
      button: ".view-more-button", // 記事を読み込むトリガーとなる要素を指定
      scrollThreshold: false, // スクロールで読み込む：falseで機能停止（デフォルトはtrue）
      status: ".page-load-status", // ステータス部分の要素を指定
      history: "false", // falseで履歴に残さない
    });
  }

}
// ============================================================
// ATTENTION & COMMON RULE!!
// まとめて関数実行（※必要に応じて条件分岐を入れる）
// ページ個別に処理をする場合は「ページ固有のID名.lengthで処理を分岐」
// ============================================================

// --- ページの全データを読み込み後 ----------------------------------
$(window).on("load", function() {});

// --- リサイズが走った場合 ----------------------------------------
$(window).on("resize", function() {});

// --- DOM生成後 -----------------------------------------------
$(function() {
  console.log("script.js");
  topPageFunc();
  // SMOOTH SCROOL ATTRIBUTE NAME
  $('a[href^="#"]').click(function() {
   
    var speed = 500;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? "html" : href);
    var position = target.offset().top;
    if (mq("sp")) {
      console.log("SPPPP");
      $("body").removeClass("menu-show");
      var position = target.offset().top - 70;

    }
    $("html, body").animate({ scrollTop: position }, speed, "swing");

    return false;
  });


  // MENU
  $("#menuTrigger").click(function() {
    if ($("body").hasClass("menu-show")) {
      $("body").removeClass("menu-show");
    } else {
      $("body").addClass("menu-show");
    }
  });
 

  subPageFunc();
  // PC or SPサンプル
  if (mq("sp")) {
    console.log("Mobile");
  } else {
    console.log("Desktop");
  }
});

// SCROLL AND TOCH START ANIMATION
$(window).on("touchstart scroll", function() {
 
});
